@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  color: black;
  font-family: 'Inter', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.login-height {
  height: 96%;
}

#summary-collection-container {
  height: 91.99vh;
}

#summary-view ul,
.post-content ul,
.summary-card ul {
  list-style: disc;
  margin-left: 16px;
}

#summary-view a,
.post-content a,
.summary-card a {
  color: #009cdd;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 10px;
}

.signup-header {
  padding: 1.83333333vw 3.33333333vw;
}

@media only screen and (min-width: 480px) and (max-width: 1199px) {
  .signup-header {
    padding: 22px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .signup-header {
    padding: 22px 8px;
  }
}

.trendingItemCard li,
.trendingItemCard li strong {
  display: inline;
}

.rdt_TableHead {
  display: none !important;
}

.chatbot-message {
  :not(:last-child) {
    margin-bottom: 6px;
  }
}

.pdfContainer:hover .pdfBtns {
  opacity: 1 !important;
}

.emoji {
  font-family: 'Noto Color Emoji', sans-serif;
}
